import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const replaceEidxLinks = (text: string): string => {
  const regex = /\[([^\]]+)\]\(eid:([0-9a-fA-F-]+)\)/g;
  if (!text) {
    return text;
  }
  return text.replace(
    regex,
    (_, name) => `${name}`
    // `[${name}](https://beta.exposit.ai/entity/id/${id})`
  );
};
